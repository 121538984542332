export const FIELD_FORCE_GROUP = 'sso_field_force';
export const SUPPORT_GROUP = 'sso_support';
export const SALES_GROUP = 'sso_sales';
export const SALES_COORDINATORS_GROUP = 'sso_sales_coord';
export const SALES_ADMIN_GROUP = 'sso_sales_admin';
export const DEV_GROUP = 'sso_dev';
export const PUBLIC_GROUP = 'sso_public';
export const PARAMETRIC_GROUP = 'sso_parametric';
export const PROJECTS_GROUP = 'sso_projects';

export const LS_USER_GROUPS = [
  FIELD_FORCE_GROUP,
  SUPPORT_GROUP,
  SALES_GROUP,
  SALES_ADMIN_GROUP,
  SALES_COORDINATORS_GROUP,
  DEV_GROUP,
  PARAMETRIC_GROUP,
  PROJECTS_GROUP,
];

export const CURRENT_RELEASE_GROUPS = [
  SUPPORT_GROUP,
  DEV_GROUP,
  SALES_COORDINATORS_GROUP,
];

export const LS_IT_GROUPS = [SUPPORT_GROUP, DEV_GROUP];

export const SALES_GROUPS = [
  SALES_GROUP,
  SALES_ADMIN_GROUP,
  SALES_COORDINATORS_GROUP,
];

export const SALES_AND_IT_GROUP = [
  SUPPORT_GROUP,
  DEV_GROUP,
  SALES_GROUP,
  SALES_ADMIN_GROUP,
  SALES_COORDINATORS_GROUP,
];

export const INNENDIENDST_AND_IT_GROUP = [
  SUPPORT_GROUP,
  DEV_GROUP,
  SALES_COORDINATORS_GROUP,
];
